<template>
  <div class="skills">
    <div class="g-container">
      <h1>My Skills</h1>
      <ul>
        <li>
          <span
            ><img src="@/assets/img/html.png" alt="html" draggable="true"
          /></span>
          <h3>HTML5</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/pugjs.png" alt="pug.js" draggable="true"
          /></span>
          <h3>pug.js</h3>
        </li>
        <li>
          <span
            ><img
              src="@/assets/img/semantic.png"
              alt="Semantic HTML"
              draggable="true"
          /></span>
          <h3>Semantic HTML</h3>
        </li>
      </ul>
      <ul>
        <li>
          <span
            ><img src="@/assets/img/css3.png" alt="CSS3" draggable="true"
          /></span>
          <h3>CSS3</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/sass.png" alt="Sass-Scss" draggable="true"
          /></span>
          <h3>Sass-Scss</h3>
        </li>
        <li>
          <span
            ><img
              src="@/assets/img/bootstrap.png"
              alt="bootstrap"
              draggable="true"
          /></span>
          <h3>bootstrap</h3>
        </li>
        <li>
          <span
            ><img
              src="@/assets/img/responsive.png"
              alt="Media Queries"
              draggable="true"
          /></span>
          <h3>Media Queries</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/flexbox.png" alt="Flexbox" draggable="true"
          /></span>
          <h3>Flexbox</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/cssgrid.png" alt="Grid" draggable="true"
          /></span>
          <h3>Grid</h3>
        </li>
      </ul>
      <ul>
        <li>
          <span
            ><img
              src="@/assets/img/javascript.png"
              alt="Javascript"
              draggable="true"
          /></span>
          <h3>Javascript</h3>
        </li>
        <li>
          <span
            ><img
              src="@/assets/img/jsdom.png"
              alt="Javascript DOM"
              draggable="true"
          /></span>
          <h3>Javascript DOM</h3>
        </li>
        <li>
          <span
            ><img
              src="@/assets/img/javascript.png"
              alt="Javascript Bom"
              draggable="true"
          /></span>
          <h3>Javascript Bom</h3>
        </li>
        <li>
          <span
            ><img
              src="@/assets/img/es6.png"
              alt="ECMAScript 6-ES6"
              draggable="true"
          /></span>
          <h3>ECMAScript 6 (ES6)</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/jsoop.png" alt="JS OOP" draggable="true"
          /></span>
          <h3>JS OOP</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/jquery.png" alt="jQuery" draggable="true"
          /></span>
          <h3>jQuery</h3>
        </li>
      </ul>
      <ul>
        <li>
          <span
            ><img src="@/assets/img/vue.png" alt="Vue" draggable="true"
          /></span>
          <h3>Vuejs</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/vuex.png" alt="Vuex" draggable="true"
          /></span>
          <h3>Vuex</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/nuxt-js.png" alt="Nuxt js" draggable="true"
          /></span>
          <h3>Nuxt.js</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/axios.png" alt="Axios" draggable="true"
          /></span>
          <h3>Axios</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/json.png" alt="Json" draggable="true"
          /></span>
          <h3>Json</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/ajax.png" alt="ajax" draggable="true"
          /></span>
          <h3>ajax</h3>
        </li>
      </ul>
      <ul>
        <li>
          <span
            ><img src="@/assets/img/webpack.png" alt="webpack" draggable="true"
          /></span>
          <h3>webpack</h3>
        </li>
        <li>
          <span
            ><img src="@/assets/img/gulp-js.png" alt="gulp js" draggable="true"
          /></span>
          <h3>gulp.js</h3>
        </li>
      </ul>
      <ul>
        <li>
          <span
            ><img
              src="@/assets/img/photoshop.png"
              alt="Photoshop"
              draggable="true"
          /></span>
          <h3>Photoshop</h3>
        </li>
        <li>
          <span
            ><img
              src="@/assets/img/illustratoricon.png"
              alt="Illustrator"
              draggable="true"
          /></span>
          <h3>Illustrator</h3>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Skills",
};
</script>

<style lang="scss" scoped>
.skills {
  padding: 50px 0;
  .g-container {
    h1 {
      text-align: center;
      margin-bottom: 40px;
    }
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      margin-bottom: 30px;
      li {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        padding: 10px;
        box-shadow: 0 0 5px rgba(94, 104, 121, 0.288);
        border-radius: 8px;
        transition: all 0.3s ease-in-out;
        border: 1px solid #ffffff73;
        cursor: pointer;
        span {
          width: 80px;
          margin-right: 8px;
        }
        &:hover {
          box-shadow: none;
          border: 1px solid #e3e3e387;
        }
      }
    }
  }
}
</style>
